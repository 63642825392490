import React, { useState } from 'react';
import { AuthenticatedTemplate } from '@azure/msal-react';
import AssetInfoCard from './AssetInfoCard';

const AssetInfo = () => {
    // TODO: make excel-like table with filterable columns
    const [asset, setAsset] = useState('');

    const handleSelectAsset = (event) => {
        setAsset(event.target.value);
    }

    return (
        <>
            <AuthenticatedTemplate>
                <section>
                    <h2>Asset Info</h2>
                    <select value={asset} onChange={handleSelectAsset}>
                        <option value="">Select an asset</option>
                        <option value="acorn">Acorn</option>
                        <option value="ppa_grand">PPA Grand</option>
                        <option value="santa_paula">Santa Paula</option>
                        <option value="wildcat">Wildcat</option>
                        <option value="portfolio">Portfolio</option>
                    </select>
                    <AssetInfoCard
                        name="Acorn"
                        pNode="EISENHOW_1_N038"
                        iso="CAISO"
                        address="123 Main St, City, Country"
                        cod="2022-01-31"
                        schedulingAgent="BETM"
                        power={3}
                        capacity={6}
                        rte="0.86"
                    />

                </section>
            </AuthenticatedTemplate>
        </>
    )
};

export default AssetInfo;