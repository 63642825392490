import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { v4 as uuidv4 } from 'uuid';
import { ISO_NAMES } from '../constants';

function TradeTicket() {
    const [iso, setIso] = useState('');
    const [asset, setAsset] = useState('');
    const [assets, setAssets] = useState([]);
    const [buildId, setBuildId] = useState(null);

    useEffect(() => {
        if (buildId) {
            handleIsoChange(iso);
        }
    }, [buildId]);

    const handleIsoChange = (value) => {
        setIso(value);
        if (value === 'caiso') {
            setAssets([
                { value: "acorn", label: "Acorn" },
                { value: "ppa_grand", label: "PPA Grand" },
                { value: "wildcat", label: "Wildcat" },
                { value: "portfolio", label: "Portfolio" },
            ]);
        } else if (value === 'ercot') {
            setAssets([
            ]);
        }
    };

    const handleSubmit = event => {
        event.preventDefault();
        console.log(`ISO: ${iso}, Asset: ${asset}`);
    };

    const handleBuild = () => {
        const id = uuidv4();
        setBuildId(id);
        console.log('Build Trade Ticket button clicked, ID:', id);
    };

    return (
        <div className="container" style={{ maxWidth: '600px' }}>
            {!buildId && (
                <button type="button" className="btn btn-secondary m-3" onClick={handleBuild}>
                    Build Trade Ticket
                </button>
            )}
            {buildId && (
                <div>
                    <p className="m-3">Trade Ticket ID: {buildId}</p>
                    <form onSubmit={handleSubmit} className="m-3">
                        <div className="mb-3">
                            <label htmlFor="isoSelect" className="form-label">
                                ISO
                            </label>
                            <select
                                className="form-select"
                                id="isoSelect"
                                value={iso}
                                onChange={e => handleIsoChange(e.target.value)}
                            >
                                {ISO_NAMES.map((option, index) => (
                                    <option key={index} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                        {assets.length > 0 && (
                            <div className="mb-3">
                                <label htmlFor="assetSelect" className="form-label">
                                    Asset
                                </label>
                                <select
                                    className="form-select"
                                    id="assetSelect"
                                    value={asset}
                                    onChange={e => setAsset(e.target.value)}
                                >
                                    {assets.map((option, index) => (
                                        <option key={index} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        )}
                        {(iso && asset) && (
                            <button type="submit" className="btn btn-primary">
                                Submit
                            </button>
                        )}
                    </form>
                </div>
            )}
        </div>
    );
}

export default TradeTicket;