import React from 'react';

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import PageLayout from './components/PageLayout';
import Reports from './pages/Reports';
import AssetInfo from './pages/AssetInfo';
import Home from './pages/Home';
import Simulator from './pages/Simulator';
import Trading from './pages/Trading';

const Pages = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/simulator" element={<Simulator />} />
      <Route path="/reports" element={<Reports />} />
      <Route path="/asset-info" element={<AssetInfo />} />
      <Route path="/trading" element={<Trading />} />
    </Routes>
  );
};

const App = () => {
  return (
    <BrowserRouter>
      <PageLayout>
        <Pages />
      </PageLayout>
    </BrowserRouter>
  );
};

export default App;

