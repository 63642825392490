import React from 'react';
import { AuthenticatedTemplate } from '@azure/msal-react';
import Clock from '../components/Clock';

const Home = () => {
    return (
        <>
            <AuthenticatedTemplate>
                <section className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-5">
                            <div className="d-flex justify-content-between">
                                <div className="text-center">
                                    <Clock timezone={-7} label="PT" />
                                </div>
                                <div className="divider-vertical"></div>
                                <div className="text-center">
                                    <Clock timezone={-5} label="CDT" />
                                </div>
                                <div className="divider-vertical"></div>
                                <div className="text-center">
                                    <Clock timezone={0} label="UTC" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </AuthenticatedTemplate>
        </>
    );
};

export default Home;
