import React, { useState } from 'react';
import useAuth from '../hooks/useAuth';
import { AuthenticatedTemplate } from '@azure/msal-react';
import { authGet } from '../authGet';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import Plot from 'react-plotly.js';

const Reports = () => {
    const [asset, setAsset] = useState('');
    const [reportType, setReportType] = useState('');
    const [date, setDate] = useState('');
    const [selectedDay, setSelectedDay] = useState(null);
    const [selectedMonth, setSelectedMonth] = useState(null);
    const [reportData, setReportData] = useState('');
    const [error, setError] = useState(null);
    const endpoint = process.env.REACT_APP_API_URL + "/reports";

    const loginRequest = {
        scopes: [process.env.REACT_APP_BACKEND_SCOPES]
    };

    const { account, getToken } = useAuth(loginRequest);

    const handleSelectAsset = (event) => {
        setAsset(event.target.value);
    };

    const handleSelectReportType = (event) => {
        setReportType(event.target.value);
    };

    const handleDayChange = date => {
        setSelectedDay(date);
        const formattedDate = date.toISOString().slice(0, 10);
        setDate(formattedDate);
    };

    const handleMonthChange = date => {
        setSelectedMonth(date);
        const formattedDate = date.toISOString().slice(0, 10);
        setDate(formattedDate);
    };


    const fetchReportData = () => {
        if (!account || !asset || !reportType || !date) {
            return;
        }
        setError(null);
        getToken().then(token => {
            if (token) {
                const params = { "reportType": reportType, "asset": asset, "date": date };
                authGet(endpoint, token, params)
                    .then(response => response.json())
                    .then(data => {
                        setReportData(data);
                    })
                    .catch(error => {
                        setError("Error fetching report data");
                    });
            }
        });
    };

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <AuthenticatedTemplate>
            <section style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                <h2>Asset Performance Reports</h2>
                <div>
                    <select value={asset} onChange={handleSelectAsset} className="me-2">
                        <option value="">Select an asset</option>
                        <option value="acorn">Acorn</option>
                        <option value="ppa_grand">PPA Grand</option>
                        <option value="wildcat">Wildcat</option>
                        <option value="portfolio">Portfolio</option>
                    </select>
                    <select value={reportType} onChange={handleSelectReportType} className="me-2">
                        <option value="">Select a report type</option>
                        <option value="daily">Daily</option>
                        <option value="weekly">Weekly</option>
                        <option value="monthly">Monthly</option>
                    </select>
                    {reportType === 'monthly' && (
                        <DatePicker
                            showIcon
                            selected={selectedMonth}
                            onChange={handleMonthChange}
                            showMonthYearPicker
                            dateFormat="MM/yyyy"
                            placeholderText='Select a month and year'
                        />
                    )}
                    {(reportType === 'daily' || reportType === 'weekly') && (
                        <DatePicker
                            showIcon
                            selected={selectedDay}
                            onChange={handleDayChange}
                            dateFormat="MM/dd/yyyy"
                            placeholderText='Select a date'
                        />
                    )}
                    <button onClick={fetchReportData} className="btn btn-primary ms-2">Generate Report</button>
                </div>

                <div>
                    {reportData && reportData.map((plotDataString, index) => {
                        if (!plotDataString) {
                            return null;
                        }
                        const plotData = JSON.parse(plotDataString);
                        return (
                            <div key={index}>
                                <Plot data={plotData.data} layout={plotData.layout} />
                            </div>
                        );
                    })}
                </div>
            </section>
        </AuthenticatedTemplate>
    );
};

export default Reports;
