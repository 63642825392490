import React from 'react';

function AssetInfoCard(props) {
    const {
        name,
        pNode,
        iso,
        address,
        cod,
        schedulingAgent,
        power,
        capacity,
        rte,
    } = props;

    return (
        <div className="asset-info-card">
            <br></br>
            <div className="name">
                <strong>Name:</strong> {name}
            </div>
            <div className="info-field">
                <strong>PNode:</strong> {pNode}
            </div>
            <div className="info-field">
                <strong>ISO:</strong> {iso}
            </div>
            <div className="info-field">
                <strong>Address:</strong> {address}
            </div>
            <div className="info-field">
                <strong>COD:</strong> {cod}
            </div>
            <div className="info-field">
                <strong>Scheduling Agent:</strong> {schedulingAgent}
            </div>
            <div className="info-field">
                <strong>Power:</strong> {power} MW
            </div>
            <div className="info-field">
                <strong>Capacity:</strong> {capacity} MW
            </div>
            <div className="info-field">
                <strong>RTE:</strong> {rte}
            </div>
        </div>
    );
}

export default AssetInfoCard;
