import React, { useState } from 'react';
import { AuthenticatedTemplate } from '@azure/msal-react';
import TradeTicket from '../components/TradeTicket';

const Trading = () => {
    return (
        <>
            <AuthenticatedTemplate>
                <section>
                    <TradeTicket />
                </section>
            </AuthenticatedTemplate>
        </>
    );
};

export default Trading;