import { AuthenticatedTemplate } from '@azure/msal-react';

const Simulator = () => {
    return (
        <>
            <AuthenticatedTemplate>
                <section>
                    <h2>esVsim</h2>
                    <p>PLACEHOLDER</p>
                </section>
            </AuthenticatedTemplate>
        </>
    );
};

export default Simulator;