export const ISO_NAMES = [
  { value: "caiso", label: "CAISO" },
  { value: "ercot", label: "ERCOT" },
]

export const MARKET_OPTIONS = [
  { value: "day_ahead", label: "Day Ahead" },
  { value: "real_time", label: "Real Time" },
];

export const ASSET_OPTIONS = [
    { value: "acorn", label: "Acorn" },
    { value: "ppa_grand", label: "PPA Grand" },
    { value: "wildcat", label: "Wildcat" },
    { value: "portfolio", label: "Portfolio" },
  ];

  