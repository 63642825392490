/**
 * Makes an authorized GET request to the provided URL. Returns the response as JSON.
 * @param {string} url The URL to make the GET request to.
 * @param {string} accessToken The access token to authorize the request.
 */
export async function authGet(url, accessToken, params = {}) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);
    const searchParams = new URLSearchParams(params);
    const urlWithParams = `${url}?${searchParams.toString()}`;

    const options = {
        method: "GET",
        headers: headers
    };
    try {
        const response = await fetch(urlWithParams, options);

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return await response;
    } catch (error) {
        console.error(error);
        throw error;
    }
}