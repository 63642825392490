import React, { useState, useEffect } from 'react';

function Clock({ timezone, label }) {
    const [time, setTime] = useState(new Date());

    useEffect(() => {
        const timerID = setInterval(() => {
            setTime(new Date());
        }, 1000);

        return () => clearInterval(timerID);
    }, []);

    const getTimeForTimezone = (tz) => {
        const utcOffset = time.getTimezoneOffset();
        const newTime = new Date(time.getTime() + (utcOffset * 60 * 1000) + (tz * 60 * 60 * 1000));
        const options = { hour12: false, hour: '2-digit', minute: '2-digit' };
        return newTime.toLocaleTimeString([], options);
    };

    return (
        <div style={{ fontSize: '11px' }}>
            <p><strong>{label}:</strong> {getTimeForTimezone(timezone)}</p>
        </div>
    );
}

export default Clock;
