import { useMsal } from "@azure/msal-react";
import { useEffect, useCallback } from 'react';

const useAuth = (loginRequest) => {
    const { instance, accounts } = useMsal();
    const account = accounts[0] || null;

    const getToken = useCallback(async () => {
        if (account) {
            try {
                const response = await instance.acquireTokenSilent({
                    ...loginRequest,
                    account: account
                });
                return response.accessToken;
            } catch (error) {
                console.error(error);
            }
        }
        return null;
    }, [account, instance, loginRequest]);

    useEffect(() => {
        if (account) {
            getToken();
        }
    }, [account, getToken]);

    return { account, getToken };
};

export default useAuth;